<template>
  <div>
    <RegHeader></RegHeader>
    <v-card-text class="dialog-content is-reg" :class="{'_white' : $isMobile() }">
      <ValidationObserver ref="observer" v-slot="{ validate }">
          
          <div class="space-y-2">
            <ValidationProvider
            name="currency"
            rules="required"
            v-slot="{ errors }"
          > 
          <v-select
    class="is-reg__select"
    dense
    hide-details
    :items="currencies"
    :menu-props="{ bottom: true, offsetY: true, contentClass: 'selectMain' }"
    v-model="regData.currency"
    :label="$t('currency')"
    color="var(--main-flamingo)"
    outlined
    >
    <!-- item-text="text"
    item-value="value" -->
  <template v-slot:selection="{ item }">
    <div class="d-flex align-center">
      <!-- <img
        :src="item.image"
        width="24"
        height="24"
        class="mr-2"
      /> -->
      {{ item.text }}
    </div>
  </template>
  <template v-slot:item="{ item }">
    <div class="d-flex align-center">
        <!-- <img
          :src="item.image"
          width="24"
          height="24"
          class="mr-2"
        /> -->
      <v-list-item-content>
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item-content>
    </div>
  </template>
    </v-select>
              <span v-if="errors.length" class="input-error mt-1">
                  {{ $t('requiredField') }}
                  <!-- {{ $t(errors[0]) }} -->
              </span>
        </ValidationProvider>
        <ValidationProvider
                      name="incognit"
                      rules="required"
                      v-slot="{ errors }"
                    > 
                    <v-checkbox v-model="regData.show_info" 
                    id="incognit"
                    color="var(--main-flamingo)"
                    hide-details
                    class="mt-0 pt-0">
                        <template v-slot:label>
                          <label for="incognit" class="checkbox-label">
                            <span>{{ $t('playerIncognit') }}</span>
                          </label>
                        </template>
                      </v-checkbox>
                        <span v-if="errors.length" class="input-error mt-1">
                            {{ $t('requiredField') }}
                            <!-- {{ $t(errors[0]) }} -->
                        </span>
                  </ValidationProvider>
                    <ValidationProvider
                    ref="agreeProvider"
                      name="agree"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="{ errors }"
                    > 
                    <v-checkbox v-model="agree" 
                    id="agree"
                    color="var(--main-flamingo)"
                    hide-details
                    class="mt-0 pt-0">
                        <template v-slot:label>
                          <label for="agree" class="checkbox-label">
                            <span>{{ $t('acceptRules') }}</span>
                            <button class="font-weight-bold text-decoration-underline"
                            @click.stop="rulesModalOpen"
                            > 
                                {{ $t('privacyPolicyRules') }} 
                            </button>
                          </label>
                        </template>
                      </v-checkbox>
                        <span v-if="errors.length" class="input-error mt-1">
                            {{ $t('requiredField') }}
                            <!-- {{ $t(errors[0]) }} -->
                        </span>
                  </ValidationProvider>
                  <ValidationProvider
                      name="agreeBonuses"
                        rules=""
                      v-slot="{ errors }"
                    > 
                    <v-checkbox 
                    disabled
                    v-model="agreeBonuses" 
                    id="agreeBonuses"
                    color="var(--main-flamingo)"
                    hide-details
                    class="mt-0 pt-0 custom-checkbox">
                        <template v-slot:label>
                          <label for="agreeBonuses" class="checkbox-label font-weight-bold">
                            <span>{{ $t('wannaGetBonuses') }} 🎁💰</span>
                          </label>
                        </template>
                      </v-checkbox>
                        <span v-if="errors.length" class="input-error mt-1">
                            {{ $t('requiredField') }}
                            <!-- {{ $t(errors[0]) }} -->
                        </span>
                  </ValidationProvider>
      </div>
      <v-btn 
      :loading="loading" 
      class="all-buttons-fw all-buttons-fw_green mt-2" style="" 
         @click="handleSubmit(validate)">
        {{ $i18n.t("register") }}
      </v-btn>
      <div style="max-width: 300px; margin: 0 auto">
        <vue-recaptcha ref="recaptcha" size="normal" sitekey="6LegZa4ZAAAAAEjT1CO6rI8QGcUSTxpWVG5u6JgM"
          class="mt-2 g-recaptcha" @verify="captchaPassed = true" @expired="onCaptchaExpired" theme="light"
          badge="inline" />
          <span v-if="captchaError" class="input-error mt-1">
            {{ $t('requiredField') }}
          </span>
      </div>
      <reg-banner-bonuses
            class="mt-2"
            v-if="$isMobile()"
            />
    </ValidationObserver>
  </v-card-text>
    <!-- <RulesModal /> -->
  </div>
</template>

<script>
import RegBannerBonuses from "./RegBannerBonuses.vue";
import fingerprint from "@/plugins/fingerprint";
import VueRecaptcha from "vue-recaptcha";
import RegHeader from "./RegHeader.vue";
// import RulesModal from "@/views/RulesModal";
import { mapGetters } from "vuex";
export default {
  components: {
    RegBannerBonuses,
    RegHeader,
    VueRecaptcha,
    // RulesModal,
  },
  name: "RegInstant",
  data() {
    return {
      selectedOption: "emailReg",
      captchaPassed: false,
      captchaError: false,
      showUser: true,
      loading: false,
      agree: false,
      agreeBonuses: true,
      regData: {
        currency: 'USD',
        show_info: true,
      }
    };
  },
  methods: {
    rulesModalOpen() {
      this.$store.commit("regComponents/setRulesModal", true);
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.captchaPassed = false;
    },
    handleSubmit(validate) {
      this.captchaError = false;
      if (!this.captchaPassed) {
        this.captchaError = true;
        return
      }
      const agree = this.$refs.agreeProvider.validate();
      validate().then((success) => {
        if (success && agree) {
          this.clickReg();
        }
      })
    },
    async clickReg() {
      this.loading = true;
      const finger = await fingerprint.get();
      //let regSuccess = false;
      const data = this.regData;
      const [res, id, pass, error] = await this.$store.dispatch(
        "awaitClickReg",
        {
          show_info: data.show_info,
          fingerprint: finger,
          // captcha: captchaResponse,
          currency: data.currency,
        }
      );
      if (res) {
        this.$store.commit("setIsInstaReg", false);
        this.$store.commit("setIsInstaReg", true);
        await this.$router.push({
          name: "DepositMainPage",
        });
        await this.$store.commit('updateRegData', {
          id: id,
          pass: pass,
        });
        const notification = {
          type: "info",
          message: this.$t('alertGoToProfile'),
          click: 'goToProfile'
        };
        this.$store.dispatch("alerts/addEternal", notification);
        this.$store.dispatch('initializeWebSocket');
      } else if (error) {
        this.$store.commit("regComponents/setSwitchInstant", false);
        this.$store.commit("regComponents/setInstantReg", true);
        const notification = {
          type: "error",
          message: this.$t("justError"),
        };
        this.$store.dispatch("alerts/add", notification);
      }
      this.loading = false;
      this.$store.commit("regComponents/setRulesModal", false);
    },
  },
  computed: {
    ...mapGetters({
      currencies: "getCurrent",
    }),
  },
};
</script>

<style lang="scss" scoped>
.dialog-email-content {
  position: relative;
  z-index: 2;
}


.is-reg {
  //background-color: transparent;
  padding: 20px!important;
 &._white{
  //background-color: var(--white);
 }
 span{
  display: inline-block;
  width: 100%;
 }
  &__form {
      
  }
  &__button{
      transition: all 0.3s;
      display: flex;
      justify-content:center;align-items: center;
      p{
          transition: all 0.3s;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          margin: 0;
      }
      span{
          transition: all 0.3s;
          position: static;
      }
      &._success{
          background-color: var(--green-success)!important;
          p{
              opacity: 1;
              overflow: visible;
              position: static;
          }
         span{
          opacity: 0;
          overflow: hidden;
          position: absolute;
         }
      }
  }
}
</style>
