<template>
    <v-dialog 
    :retain-focus="false" 
    transition 
    class="absolute-list-reg__dialog dialog" 
    :fullscreen="$isMobile() ? true : false" 
    :content-class="$isMobile() ? 'dialog' : 'passportDialog'"
    v-model="dialogPromo" 
    max-width="500">
      <v-card class="dialog__card dialog-card-login">
        <v-card-title class="dialog__card-header dialog-card-header">
          <v-row class="dialog-card-header__items">
            <v-col class="dialog-card-header__item">
              <img :src="`https://minio.takemybet.pro/ui-style/${logo}`" class="dialog-card-header__logo" alt="main-logo" />
            </v-col>
            <v-col class="dialog-card-header__item">
              <span>
                {{ $i18n.t('promoTitle') }}
              </span>

            </v-col>
            <v-col class="dialog-card-header__item">
              <v-btn class="dialog-card-header__close" @click="closeDialogPromo">
                <v-icon color="black">close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="dialog__card-content dialog__card-content_promo dialog-content">
          <v-form v-model="validPromo" class="dialog-content__form">
            <div class="text">{{ $i18n.t('promoEnter') }}</div>

            <v-text-field class="dialog-content__input dialog-content__input_promo" outlined autofocus hide-details
              :label="$t('promoTitle')" v-model="promo" color="transparent">

              <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                redeem
              </v-icon>
              <template v-slot:append>
                <v-btn :loading="loadingFlag" style="color: var(--white)!important" :disabled="isSubmitPromoButtonDisabled"
                  :style='{ opacity: isSubmitPromoButtonDisabled ? 0.5 : 1 }'
                  class="all-buttons-fw all-buttons-fw_promo dialog-content__button " @click="activatePromoCode">
                  {{ $i18n.t('promoButton') }}
                </v-btn>
              </template>
            </v-text-field>
            <div class="dialog-content__success-message-promo" :class="{ '_active': promoActivated }">
              {{ $i18n.t('promoSuccess') }}
            </div>
            <div class="dialog-content__error-message-promo" :class="{ '_active': promoError }">
              {{ $i18n.t('promoError') }}
            </div>

          </v-form>

        </v-card-text>

      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Promocode',
  data() {
    return {
      promo: '',
      loadingFlag: false,
      promoActivated: false,
      promoError: false,
      validPromo: false,
    }
  },
  computed: {
    ...mapGetters({
      logo: 'styles/getLogo',
    }),
    isSubmitPromoButtonDisabled() {
      return this.promo === '';
    },
    dialogPromo: {
      get() {
        return this.$store.state.promoCode.dialogPromo;
      },
      set(value) {
        this.$store.commit('promoCode/setDialogPromo', value);
      },
    },
  },
  methods: {
    closeDialogPromo() {
      this.$store.commit('promoCode/setDialogPromo', false);
    },
    async activatePromoCode() {
      this.loadingFlag = true;
      this.promoActivated = false;
      this.promoError = false;
      await this.$store.dispatch('activatePromocode', this.promo)
      if (this.$store.state.promocodeIsExists) {
        this.promoActivated = true;
        setTimeout(() => {
          this.$store.commit('promoCode/setDialogPromo', false);
          this.promoActivated = false;
          this.promo = '';
        }, 1000);
      } else {
        this.promoError = true;
      }
      this.loadingFlag = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.text{
  color: var(--black);
  font-size: 16px;
  line-height: 24px; margin-bottom: 15px;
  text-align:left;
}
.dialog-card-header {
  background: #f2f6f9;
  border-bottom: 1px solid #dfe4e8;
  padding: 3px 15px !important;

  .dialog_pc & {
    padding: 15px !important;
  }

  &__items {
    margin: 0;
    align-items: center;
    flex-wrap: nowrap;

    .dialog_pc & {
      justify-content: flex-end;
    }

  }

  &__item {
    flex: 0 1 33.333% !important;
    padding: 0;
    display: flex;
    justify-content: center;

    &:last-child {
      justify-content: flex-end;
    }

    &:first-child {
      justify-content: flex-start;
    }

    .dialog_pc & {
      &:first-child {
        justify-content: flex-end;
      }
    }

    span {
      color: var(--black);
      font-size: 15px;
      white-space: nowrap;
      font-weight: 600;
      line-height: 1.5;
      text-transform: uppercase;
    }

    @media (min-width:850px) {
      flex: 1 1 100% !important;

    }
  }

  &__logo {
    width: 65px;
    height: 65px;

    img {}
  }

  &__close {
    padding: 0 !important;
    cursor: pointer;
    min-width: 36px !important;
    height: 36px !important;
    box-shadow: none !important;
    background-color: var(--white);
    align-items: center;
    background-color: var(--white) !important;
    border: none;
    border-radius: 6px;

  }
}

.dialog-content {


  &__form {
    padding: 20px 0px;
  }


  &__input {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      z-index: 50;
      width: 100%;
      top: 43px;
      left: 0;
      background: #dfe4e8;
      height: 2px;
    }

    &_promo {


      &::before {
        content: none;

      }

      z-index: 5;
      background-color: var(--white);
    }


  }

  &__success-message-promo {
    background-color: var(--green-success);
    color: var(--white);
    font-weight: 500;
    padding: 5px;
    text-align: center;
    position: relative;
    top: -50px;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: top 0.3s ease, opacity 0.3s ease, visibility 0.3s;

    &._active {
      top: 0;
      opacity: 1;
      visibility: visible;
    }
  }

  &__error-message-promo {
    background-color: var(--red);
    color: var(--white);
    font-weight: 500;
    padding: 5px;
    text-align: center;
    position: relative;
    top: -50px;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: top 0.3s ease, opacity 0.3s ease, visibility 0.3s;

    &._active {
      top: -32px;
      opacity: 1;
      visibility: visible;
    }
  }


}</style>