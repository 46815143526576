var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"max-width":"900px"},attrs:{"fluid":""}},[_c('div',{staticStyle:{"color":"var(--white)"}},[_vm._v(" "+_vm._s(_vm.$i18n.t("profileStatusInfoText"))+" ")]),_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"550px"}},[(_vm.currentLevelNumber < _vm.lvlItems.length)?_c('v-row',[_c('v-col',{staticClass:"text-left d-flex flex-column",staticStyle:{"color":"var(--white)"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t("toNextStatusLevel"))+": "),(_vm.nextBonusAmount !== null && _vm.betAmount)?_c('b',[_vm._v(_vm._s(_vm.betAmount)+" / "+_vm._s(_vm.nextBonusAmount)+" "+_vm._s(_vm.user.currency))]):_c('b',[_vm._v(_vm._s(_vm.$t("Loading"))+"...")])]),(_vm.nextBonusAmount !== null)?_c('v-progress-linear',{staticStyle:{"border-radius":"16px","width":"100%","border":"solid 1px var(--red)"},attrs:{"height":"12px","background-color":"none","color":"var(--red)","value":_vm.progressValue}}):_c('span',[_vm._v(_vm._s(_vm.$t("LoadingProgress"))+"...")])],1)],1):_c('v-row',[_c('v-col',{staticClass:"text-center font-weight-bold",staticStyle:{"color":"var(--white)"}},[_vm._v(" "+_vm._s(_vm.$t('max_level'))+" ")])],1),_c('v-row',{staticClass:"lvl"},[(_vm.lvlItems.length && !_vm.loadingFlag)?_vm._l((_vm.lvlItems),function(lvlItem,index){return _c('v-col',{key:index,staticClass:"lvl__col",class:{
            _completedCol: Number(lvlItem.name) < _vm.currentLevelNumber,
            _completedCol_active: (Number(lvlItem.name) < _vm.currentLevelNumber) && _vm.activeLvl,
            _current: lvlItem.showIcon,
            _completed: Number(lvlItem.name) < _vm.currentLevelNumber,
          },attrs:{"cols":"12"}},[_c('levelFlip',{attrs:{"index":index}},[_c('template',{slot:"front"},[_c('div',{on:{"click":function($event){return _vm.flipLevel(lvlItem, index)}}},[_c('transition',{attrs:{"name":"scale-transition"}},[(Number(lvlItem.name) >= _vm.currentLevelNumber || _vm.activeLvl)?_c('span',{staticClass:"lvl__flip-level",on:{"click":function($event){$event.stopPropagation();return _vm.flipCompletedLevel(lvlItem, index)}}},[_c('font-awesome-icon',{staticStyle:{"font-size":"12px"},attrs:{"icon":"arrow-right"}})],1):_vm._e()]),_c('v-container',{staticClass:"lvl__container",on:{"click":function($event){return _vm._completedColClick(lvlItem)}}},[_c('div',{staticClass:"lvl__image"},[_c('img',{attrs:{"src":`https://minio.takemybet.pro/levels/${lvlItem.logo}`,"alt":lvlItem.name}})]),_c('v-col',{staticClass:"lvl__text"},[_c('span',[_vm._v(_vm._s(_vm.$t("lvl"))+" "+_vm._s(lvlItem.name)+" - "+_vm._s(_vm.$t(lvlItem.description)))]),(lvlItem.name !== '1')?_c('span',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(`${lvlItem.next_level_amount} ${_vm.user.currency} - ${_vm.$t('level_amount')}`)+" ")]):_vm._e()]),(lvlItem.showIcon)?_c('div',{staticClass:"svg-current"},[_c('img',{attrs:{"src":require("@/assets/images/white-heart.svg"),"alt":"heart"}})]):_vm._e()],1)],1)]),_c('template',{slot:"back"},[_c('v-container',{staticClass:"lvl__container back-lvl",class:{
                  '_current-back': lvlItem.showIcon,
                  '_completed-back': lvlItem.name < _vm.currentLevel,
                },on:{"click":function($event){return _vm.flipAllLevels(index)}}},[_c('span',{staticClass:"lvl__flip-level lvl__flip-level_close"},[_c('v-icon',{staticStyle:{"font-size":"18px"},attrs:{"color":"white"}},[_vm._v(" close ")])],1),_c('div',{staticClass:"lvl__image"},[_c('img',{attrs:{"src":`https://minio.takemybet.pro/levels/${lvlItem.logo}`,"alt":lvlItem.name}})]),_c('div',{staticClass:"back-lvl__content"},[_c('h3',{staticClass:"back-lvl__title"},[_vm._v(" "+_vm._s(_vm.$t('level_bonuses'))+": ")]),_c('div',{staticClass:"back-lvl__info"},[_c('div',{staticClass:"d-flex flex-column align-start",staticStyle:{"color":"var(--white)"}},[(lvlItem.dep_bonus !== null)?_c('span',{staticClass:"text-left"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('depositBonus'))+": ")]),_vm._v(" "+_vm._s(_vm.formatDepositBonus(lvlItem.dep_bonus))+" ")]):_vm._e(),(lvlItem.free_rounds_bonus !== null)?_c('span',{staticClass:"text-left"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('spinsBonuses'))+":")]),_vm._v(" "+_vm._s(_vm.formatSpinsBonus(lvlItem.free_rounds_bonus))+" ")]):_vm._e()])])])])],1)],2)],1)}):_vm._l((10),function(lvlItem,index){return _c('v-col',{key:index,staticClass:"lvl__col",attrs:{"cols":"12"}},[_c('v-container',{staticClass:"lvl__container fw"},[_c('v-skeleton-loader',{staticClass:"skeleton-level",attrs:{"width":"100%","height":"85px","type":"image"}})],1)],1)})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }