<template>
    <v-card class="bonus-back">
        <v-card-title class="bonus-back__header header-bonus">
          <div class="header-bonus__label">
            <svg xmlns="http://www.w3.org/2000/svg" fill="var(--white)" height="14px" viewBox="0 0 512 512"><path d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"/></svg>
      </div>
          <div class="header-bonus__title">{{ bonusTitle }}</div>
          <!-- @click="toggleCardFlip(index)"  -->
          <div @click="unflipCard" class="header-bonus__label"><v-icon  color="white" style="font-size: 18px;">
            close
        </v-icon></div>
        </v-card-title>
        <v-card-text style="width: auto;" class="bonus-back__main main-bonus">
          <v-list class="main-bonus__list">
            <div class="main-bonus__item">
              <v-list-item-content class="main-bonus__content" v-for="(title, key) in typeTitle" :key="'content_' + key">
                <v-list-item-title class="main-bonus__title" style="min-width: 115px;">
                  {{ title }}
                </v-list-item-title>
                <v-list-item-subtitle class="main-bonus__text">
                  {{ propsData[key] }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </div>
          </v-list>
          <v-btn :loading="loading" v-if="is_active" @click="deactivateBonus"
          class="all-buttons-t main-bonus__button mx-2">
          {{
            $t('deactivateBonus') }}</v-btn>
        </v-card-text>
      </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'BackSpinsBonus',
        props: {
          index: Number,
          bonusId: Number,
          type: String,
          count: Number,
          expire_in_days: String,
          is_active: Boolean,
          game_name: String,
          currency_bet: Object,
          wager: Number,
          lines: Number,
          bet: String,
        },
        data(){
            return{
              loading: false,
            }
        },
        computed:{
          ...mapGetters({
      user: 'getUserInfo'
    }),
  //  userBet() {
  //  return this.currency_bet[this.user.currency] || 0;
  //},
  bonusTitle() {
      if (this.type === 'welcome') {
        return this.$t('welcomeBonus');
      } else if (this.type === 'free_rounds') {
        return this.$t('levelupBonus');
      } else {
        return 'Неизвестный тип бонуса';
      }
    },
  propsData() {
      return {
        index: this.index,
        
        type: this.$t('freespinsNoCount'),
        //count: this.count,
        expire_in_days: this.expire_in_days,
        game_name: this.game_name,
        wager: this.wager,
        //lines: this.lines,
        //bet: this.userBet + ' ' + this.user.currency,
      };
    },
    typeTitle() {
      return {
        type: this.$t('type'),
        wager: this.$t('wager'),
        //count: this.$t('quantity'),
        expire_in_days: this.$t('bonusExpire'),
        game_name: this.$t('game'),
        //lines: this.$t('lines'),
        //bet: this.$t('bet'),
      };
    },
        },
        
        methods: {
         unflipCard() {
          this.$store.commit('bonuses/flipBonus', { bonuses: 'spinsBonuses', index: this.index });
    },
    async deactivateBonus() {
      // hold id to bonus
      this.loading = true;
      let notification;
        try {
          await this.$store.dispatch('bonuses/deleteUserBonus', this.bonusId)
          notification = {
              type: "success",
              message: this.$t('bonusHasBeenDeactivated'),
            };
          await this.$store.dispatch('bonuses/getUserBonuses');
        } catch (error) {
          notification = {
              type: "error",
              message: this.$t('justError'),
            };
          } finally{
            this.$store.dispatch("alerts/add", notification);
            this.loading = false;
          }
    },
        },
    }
</script>

<style lang="scss" scoped>
.bonus-back {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  background: linear-gradient(to right, var(--white), var(--white))!important;
  
  color: var(--white)!important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
    border-radius: 16px!important;
  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }
  &__content{
    
  }
  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }
  
}
.header-bonus {
  padding: 10px;
  background-color: var(--dark-flamingo);

  &__label {
    height: 24px;
    justify-content: center;
    
    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;
    &:last-child{
      cursor: pointer;
      
      @media (any-hover: hover){
        &:hover{
          &::before {
            background-color: hsla(0, 0%, 100%, .4);
          }
        }
      }
      
    }
    &::before {
      background-color: hsla(0, 0%, 100%, .2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
    
    }

  &__title {
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    
  }
}
.main-bonus{
  padding: 0;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  &__list {
    background-color: var(--white)!important;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: inherit!important;
    flex: 1 1 auto;
  }

  &__item {
    line-height: 1.5;
    
    min-height: auto;
    flex-direction: column;
    flex: 1 1 100%;
    
  }
  &__content{
    padding: 10px 20px 9px;
    display: flex;
    align-items: center;
    &:nth-child(even){
      background: #f2f6f9!important;
    }
    flex-wrap: nowrap;
  }
  &__title {
    color: var(--gray-text)!important;
    margin-right: 10px;
  }

  &__text {
    font-weight: 600;
    margin-left: auto;
    text-align: right;
    color: var(--black)!important;
  }
  &__button {
    cursor: pointer;
    font-size: 16px !important;
    background-color: var(--red);
    transition: all 0.3s;
    @media (any-hover: hover) {
      &:hover {
        box-shadow: 0 0 15px var(--red) !important;
      }
    }
  }
}
.timer-bonus {
  display: flex;
  gap: 10px;
  justify-content:center;align-items: center;
  &__icon {
  }

  &__text {
    font-weight: 700;
    color: var(--white);
  }
}
.bonus {

  &__header {
  }

  &__main {
  }
}
</style>