<template>
  <v-card style="color:var(--black)" class="mx-auto deposit-banner">
    <v-card-text class="pb-0  deposit-banner__title title-deposit-banner">
      <v-row class="mb-0 ">
        <v-col style="font-size:16px;color: var(--black-text);white-space:nowrap" cols="10"
          class="text-uppercase text-left pb-0  title-deposit-banner__item">
          {{ $t('enterCountMoney') }}
        </v-col>
        <v-col cols="2" class="  title-deposit-banner__item title-deposit-banner__current-country">

        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pt-0  deposit-banner__input input-deposit-banner">
      <v-row class=" ">
        <v-col class=" ">
          <div class="input-wrapper"
          :class="{'_error' : isAmountError}"
          >
            <div class="input-mask"
            >
              <input
              v-maska
                  data-maska="0.99"
                  data-maska-tokens="0:\d:multiple|9:\d:optional"
                  :maxlength="11"
                  class="pt-0 limit-input withdraw_amount black-input"
                  v-model="amount"
                  type="text"
                  @input="formatInput"
                  placeholder="0.00"
                  
              >
            </div>
            <div class="input-currency">{{ user.currency }}
            </div>
          </div>
          <transition name="fade-transition">
            <div class="input-hint" v-if="isAmountError">
              {{$t('min_deposit_is')}}
            </div>
          </transition>
          <!-- <v-text-field class="pt-0  limit-input withdraw_amount black-input" :maxlength="11" v-model="amount" step="0.01"
            hide-details type="text" color="white" 
            v-maska
            data-maska="0.99"
            data-maska-tokens="0:\d:multiple|9:\d:optional"
            >
            <template v-slot:append>
              <div style="font-size: 48px;color:var(--dark-flamingo)!important">{{ user.currency }}
              </div>
            </template>
          </v-text-field> -->
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pt-0  deposit-banner__select select-deposit-banner">
       <!--<v-row class="">
        <v-col class="py-0">
          <CurrenciesDeposit></CurrenciesDeposit>
        </v-col>
      </v-row> -->
      <v-chip-group class="deposit-chip">
        <v-chip @click="chip.iteration" class="deposit-chip__item px-3" v-for="(chip, index) in chips" :key="index" outlined column style="background-color:#f2f6f9!important">
          <div class="" >{{chip.value}}
          </div>
        </v-chip>
      </v-chip-group>
    </v-card-text>
    <!-- v-if="depositBonus.is_active"  -->
    <v-card-text v-if="!userHaveFirstDeposit" class="pt-0  deposit-banner__bonus select-deposit-bonus">
      <v-row class="">
        
        <v-col class="">
          <div>
            <v-icon class="select-deposit-bonus__icon" color="var(--main-flamingo)">redeem</v-icon>
            <div class="select-deposit-bonus__content">
              <div class="select-deposit-bonus__info" >
                <span style="font-size: 12px;">{{ $t('depositFirstBonusTitle') }}</span>
                <router-link to="/profile/promotions" style="text-decoration: none;">
                  <v-icon  color="var(--main-flamingo)" >info</v-icon>
                </router-link>
              </div>
              <span>200%</span>
            </div>
            <div style="position:relative;" class="select-deposit-bonus__switch">
              
              <v-switch v-model="switchDepositBonus" hide-details color="green accent-4" inset
                class="mt-0 pt-0 custom-switch custom-switch_deposit" style="margin-right: -10px"
                @change="toToggleBonus">
                
              </v-switch>
              <div v-if="depositBonusActive" @click="isAlreadyDepositBonusUsed" class="already-used"></div>
                <!--  -->
                
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="flex-column pt-0">
      <v-row>
        <v-col class=" ">
          
          <!-- <v-btn v-for="method in payMethods" :key="method.codename" @click="saveCount(method)" -->
          <v-btn @click="saveCount"
          :loading="loadingFlag"
            style=""
            class="all-buttons-t all-buttons-fw all-buttons-fw_green deposit-button mb-2"
            :disabled="isAmountInvalid">
            {{ $t('playFor') }} <span class="ml-1"
              v-if="!isAmountInvalid">{{ amountFormated
              }} {{ user.currency }}</span>
          </v-btn>
          <div class="mt-1 promo-link" style="font-size: 12px;"><span @click="openPromocodeDialog"
              style="color: var(--gray-text);">{{ $t('havePromo') }}</span></div>
          <PromoCode></PromoCode>
        </v-col>
      </v-row>
    </v-card-text>
    <CurrenciesDialog></CurrenciesDialog>
    <DepositMethods></DepositMethods>
    <!-- <DepositExit></DepositExit> -->
  </v-card>
</template>

<script>
import { vMaska } from "maska"
import CurrenciesDeposit from '@/components/Currency/CurrenciesDeposit'
import CurrenciesDialog from '@/components/Currency/CurrenciesDialog'
import PromoCode from '@/components/Profile/Promo/PromoCode/Promocode.vue'
import DepositMethods from '@/views/DepositPages/DepositWindow/DepositMethods'
import DepositExit from '@/views/DepositPages/DepositExit'
import { mapGetters } from 'vuex'
export default {
  directives: { maska: vMaska },
  name: 'DepositForm',
  components: {
    //CurrenciesDeposit,
    CurrenciesDialog,
    PromoCode,
    DepositMethods,
    // DepositExit,
  },
  watch: {
  amount(newValue) {
    //if (newValue.charAt(0) === '0' && newValue.length >= 1) {
    //  this.amount = `0.${newValue[newValue.length - 1]}`
    //} 
    const numericValue = parseFloat(newValue);
    if (numericValue < 1) {
        this.isAmountError = true;
    } else {
        this.isAmountError = false;
        if (newValue.charAt(0) === '0') {
          this.amount = this.amountFormated;
        }
    }
  },
  'user.has_first_dep': function(newValue) {
    this.switchDepositBonus = !newValue;
  }
},
  async mounted(){
    if (localStorage.getItem('jwt')) {
        await this.$store.dispatch('getPayMethods');
      }
    if (this.depositBonuses.length === 0) {
      await this.$store.dispatch('bonuses/getUserBonuses');
    }
    this.switchDepositBonus = !this.user.has_first_dep;
    this.loadingFlag = false;
  },
  data() {
    return {
      isAmountError: false,
      switchDepositBonus: true,
      loadingFlag: true,
      amount: '50.00',
      toToggleWelcomeBonus: true,
      chips:[
        {
          value: '10',
          iteration:  () => {
            this.amount = '10'
          }
        },
        {
          value: '100',
          iteration:  () => {
            this.amount = '100'
          }
        },
        {
          value: '1000',
          iteration:  () => {
            this.amount = '1000'
          }
        },
        {
          value: '5000',
          iteration:  () => {
            this.amount = '5000'
          }
        },
      ],
      
    }
  },
  computed: {
    ...mapGetters({
      depositBonuses: 'bonuses/getDepositBonuses',
      user: 'getUserInfo',
      payMethods: "getPayMethods",
    }),
    userHaveFirstDeposit(){
      return this.user.has_first_dep;
    },
    depositBonusActive() {
      return this.depositBonuses.length > 0 && this.depositBonuses[0].is_active === true;
    },
    depositWindow: {
      get() {
        return this.$store.state.depositView.depositWindow;
      },
      set(value) {
        this.$store.commit('depositView/setDepositWindowError', value);
      },
    },
    isAmountInvalid() {
    return !/^[1-9][0-9]*(\.[0-9]+)?$/.test(this.amountFormated) ||
           parseFloat(this.amountFormated) <= 0.99;
  },
  amountFormated(){
    const cleanedAmount = this.amount.replace(/[^0-9.]/g, '');
    const amountNumber = parseFloat(cleanedAmount);
    if (!isNaN(amountNumber)) {
      const roundedAmount = Math.floor(amountNumber * 100) / 100; 
      return roundedAmount.toFixed(2);
    } else {
      return null; 
    }
  },
  },
  methods: {
    formatInput(){
    //   if (this.amount.charAt(0) === '0' && this.amount.charAt(1)) {
    //     this.amount = '1'
    // }
    //console.log(this.amount.charAt(0));
    //console.log(this.amount.length);
    if (this.amount.charAt(0) === '0' && this.amount.charAt(1) === '0') {
        this.amount = '0.';
    } 
    },
    isAlreadyDepositBonusUsed(){
      this.switchDepositBonus = true;
      this.$store.commit('depositView/setAlreadyDepositBonusUsed', true);
      setTimeout(() => {
        this.$store.commit('depositView/setAlreadyDepositBonusUsed', false);
      }, 1500);
    },
    toToggleBonus() {
      this.toToggleWelcomeBonus = !this.toToggleWelcomeBonus;
      this.$store.commit('depositView/setBonusWillActive')
    },
    saveCount() {
      localStorage.setItem('moneyToDeposit', this.amountFormated);
      this.$store.commit('depositView/SET_AMOUNT', this.amountFormated);
      this.$store.commit('depositView/setDepositMethodsWindow', true);
      this.$store.commit('depositView/setMethodMoney', true);
    },
    openPromocodeDialog() {
      this.$store.commit('promoCode/setDialogPromo', true)
    },
  }
}
</script>

<style lang="scss" scoped>
.chip-item { 
  border-radius: 6px!important;
  position: relative;
  padding: 0 31px 0 32px;
  
  color: var(--black-text)!important;
  &:last-child{
    margin-right: 0!important;
  }
}
.already-used{
  position: absolute;
  width: 100%;height: 100%;
  top: 0;
  left: 0;
}
.deposit-banner {
  background-color: var(--white) !important;
  box-shadow: 0 0 42px hsla(0, 0%, 100%, .05), 0 30px 50px rgba(22, 20, 33, .3);
  border-radius: 16px !important;
  width: 390px;

  @media (max-width:600px) {
    width: 100%;
  }

  &__bonus {}
}

.select-deposit-bonus {

  .row {
    .col {
      >div {
        // background-color: var(--light-flamingo);
        border: 1px solid rgba(0, 0, 0, .12);
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 8px 15px;
        gap: 10px;
      }





    }
  }

  &__icon {}

  &__content {
    display: flex;
    flex-direction: column;
    color: var(--main-flamingo);
    align-items: flex-start;
    flex: 1 1 auto;
  }

  &__info {
    display: flex;
    align-items: center;
    font-weight: 500;

    i {
      margin-left: 3px;
      font-size: 16px;
    }
  }

  &__switch {}
}

.v-btn--disabled {
  opacity: 0.5;
}

.deposit-button {
  &:last-child {
    display: none;
  }
}

.promo-link {
  cursor: pointer;
  text-align: center;
  @media (any-hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>