<template>
  <v-card class="front-bonus">
    <div class="front-bonus">
      <v-card-title class="front-bonus__header header-bonus">
        <div class="header-bonus__label">
          <svg xmlns="http://www.w3.org/2000/svg" fill="var(--white)" height="14px" viewBox="0 0 512 512">
            <path
              d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z" />
          </svg>
        </div>
        <!-- {{ $i18n.t('bonusesFree') }} -->
        <div class="header-bonus__title"> {{ bonusTitle }}</div>
        <!-- @click="toggleCardFlip(index)"  -->
        <div @click="flipCard" class="header-bonus__label">
          <font-awesome-icon icon="info" style="font-size: 12px;" />
        </div>
      </v-card-title>
      <v-card-text style="width: auto;" class="py-0 front-bonus__main main-bonus">
        <!--<div class="main-bonus__image">
              <img src="@/assets/images/bonus.png" width="100%" /> 
            </div>-->
        <div class="main-bonus__title">
          <span>{{ count }}</span>
          <p> {{ $t('bonusesFree') }} </p>
          <!-- <p>+ 100 FREESPINS</p> -->


        </div>
        <!--   -->
        <!-- <div @click="toPlay" v-if="isFreeSpinsGame && !is_used" class="main-bonus__logo">
        <img :src="isFreeSpinsGame.logo"  />
      </div> -->
        <div v-if="game_img !== null" @click="toPlay" class="main-bonus__logo">
          <img :src="game_img" />
        </div>
      </v-card-text>


      <v-card-actions v-if="$route.path === '/profile/promotions'" class="main-bonus__activate justify-center"
        :style="{ 'background-color': isActive ? 'rgb(78 0 72 / 40%)' : 'none' }">
        <v-btn :loading="loading" v-if="!isActive && !is_used"
          class="all-buttons-t main-bonus__button main-bonus__button_fix" @click="activateBonus">{{
            $t('bonusesWillActive') }}</v-btn>
        <div v-if="isActive && !is_used" class="d-flex align-center font-weight-bold">
          <router-link to="/profile/promo-my/bonus" class="bonus-link"><span>{{ $t('bonus') }} </span></router-link>
          <span class="ml-1">{{ $t('bonusesWasActivated') }}</span>
        </div>
        <div v-if="is_used" class="d-flex align-center font-weight-bold">
          <router-link to="/profile/promo-my/bonus" class="bonus-link"><span>{{ $t('bonus') }} </span></router-link>
          <span class="ml-1">{{ $t('bonusesWasUsed') }}!</span>
        </div>
      </v-card-actions>
      <v-card-actions v-if="$route.path === '/profile/promo-my/bonus'" class="main-bonus__activate justify-center"
        :style="{ 'background-color': is_used ? 'rgb(78 0 72 / 40%)' : 'none' }">

        <v-btn :loading="loading" v-if="!isActive" class="all-buttons-t main-bonus__button main-bonus__button_fix"
          @click="activateBonus">{{ $t('bonusesWillActive') }}</v-btn>
        <div class="main-bonus__button item-timer" v-if="isActive && !is_used" @click="toPlay">{{ $t('playNow') }}</div>
        <div v-if="isActive && !is_used" class="font-weight-bold timer-block item-timer">
          <span>{{ $t('timeLeftBonus') }}</span>
          <Countdown v-if="Number(expire_in_seconds)" :expireInSeconds="expire_in_seconds" />
        </div>

        <div v-if="is_used" class="px-2 d-flex align-center justify-center font-weight-bold">
          <span class="ml-1">{{ $t('bonusWasUsed') }}!</span>
        </div>
      </v-card-actions>

    </div>
  </v-card>
</template>

<script>
import Countdown from '@/components/Countdown/Timer'
import { mapGetters } from 'vuex';
export default {
  name: 'FrontSpinsBonus',
  components: {
    Countdown
  },
  props: {

    index: {
      type: Number,
      // required: true,
    },
    bonusId: {
      type: Number,
      // required: true,
    },
    status: String,
    is_used: Boolean,
    type: String,
    count: Number,
    expire_in_days: String,
    expire_in_seconds: Number,
    game_name: String,
    game_id: Number,
    wager: Number,
    lines: Number,
    game_img: String,
    game_string_id: String,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      spinsBonuses: 'bonuses/getSpinsBonuses',
      user: 'getUserInfo',
      casinoGames: 'getGames',
    }),
    isActive () {
      return this.status === 'active';
    },
    bonusTitle() {
      if (this.type === 'welcome') {
        return this.$t('welcomeBonus');
      } else if (this.type === 'free_rounds') {
        return this.$t('levelupBonus');
      } else {
        return this.$t('justError');
      }
    },
    bonusItems() {
      if (this.spinsBonuses) {
        return this.spinsBonuses.map(bonus => ({
          isActive: bonus.isActive,
          is_used: bonus.is_used,
          type: bonus.type,
          count: bonus.count,
          expire_in_days: bonus.expire_in_days + ' ' + this.$t('day'),
          expire_in_seconds: bonus.expire_in_seconds,
          game_name: bonus.game_name,
          game_id: bonus.game_id,
          wager: bonus.wager,
          lines: bonus.lines,

        }));
      } else {
        return [];
      }
    },

  },
  methods: {
    async toPlay() {
      const gameId = this.game_string_id;
      this.$store.commit('setOpenMobileSearch', false);
      //console.log('gameId', gameId);
      const params = {
        id: gameId,
      }
      await this.$router.push({ name: 'Game', params });
    },
    async activateBonus() {
      // hold id to bonus
      this.loading = true;
      try {
        const [res, error_text] = await this.$store.dispatch('bonuses/activateUserBonus', { id: this.bonusId });
        if (res) {
          this.redirectToUserBonusesPage();
          const notification = {
              type: "success",
              message: this.$t('bonusHasBeenActivated'),
            };
          this.$store.dispatch("alerts/add", notification);
          await this.$store.dispatch('awaitGetUserInfo');
          await this.$store.dispatch('bonuses/getUserBonuses');
          this.loading = false;
        } else {
          const notification = {
              type: "error",
              message: this.$t('justError'),
            };
          this.$store.dispatch("alerts/add", notification);
          this.loading = false;
        }
      } catch (error) {
        console.error('Error:', error);
        this.loading = false;
      }
    },
    redirectToUserBonusesPage(){
      if (this.$route.path !== '/profile/promo-my/bonus') {
              this.$router.push('/profile/promo-my/bonus');
            }
    },
    flipCard() {
      this.$store.commit('bonuses/flipBonus', { bonuses: 'spinsBonuses', index: this.index });
    },

  },
}
</script>

<style lang="scss" scoped>
.front-bonus {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  background: url('../../../../../assets/images/flipBonusCard.jpg') 0 70% / cover no-repeat;
  color: var(--white) !important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px !important;

  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }

  &__content {}

  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }

}

.header-bonus {
  padding: 10px;

  &__label {
    height: 24px;
    justify-content: center;

    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;

    &:last-child {
      cursor: pointer;

      @media (any-hover: hover) {
        &:hover {
          &::before {
            background-color: hsla(0, 0%, 100%, .4);
          }
        }
      }

    }

    &::before {
      background-color: hsla(0, 0%, 100%, .2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
  }

  &__title {
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;

  }
}

.main-bonus {
  cursor: default;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  

  &__image {}

  &__title {
    transition: transform 0.3s;
    text-transform: uppercase;
    font-weight: 900;
    color: var(--white) !important;
    line-height: 1;
    position: relative;
    @media (any-hover: hover) {
      &:hover {
          transform: scale(1.1);
      }
    }
    span {
      font-size: 80px;
    }

    p {
      font-size: 40px;
    }
  }

  &__logo {
    position: relative;
    //top: 54%;
    //left: 50%;
    //transform: translate(-50%, 50%);
    width: 100px;
    height: 100px;
    border-radius: 16px;
    border: 1px solid var(--red);
    overflow: hidden;
    cursor: pointer;

    img {
      max-width: 100%;
      max-height: 100%;

    }

    &::after {
      content: '';
      top: 0;
      transform: translateX(100%);
      width: 100%;
      left: 0;
      height: 220px;
      position: absolute;
      z-index: 1;

      animation: slideLogo 1.2s infinite;
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.8)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0)));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
      /* IE10+ */
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
      /* IE6-9 */
    }

    /* animation */

    @keyframes slideLogo {
      0% {
        transform: translateX(-100%);
      }

      100% {
        transform: translateX(100%);
      }
    }
  }

  &__activate {
    position: relative;
    z-index: 2;
    border-radius: 0 0 16px 16px;
    display: flex;
  }

  &__button {
    min-height: 50px;
    display: flex;
    cursor: pointer;
    flex: 1 1 100%;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px !important;
    width: 100%;
    font-weight: 700;
    position: relative;
    z-index: 5;
    transform: translate(3px, 0px) !important;

    background-color: var(--red);
    border-radius: 6px 0 0 6px;
    transition: all 0.3s;

    @media (any-hover: hover) {
      &:hover {
        box-shadow: 0 0 15px var(--red) !important;
        transform: translate(3px, 0px) !important;
      }
    }

    &_fix {
      transform: translate(0px, 0px) !important;
      @media (any-hover: hover){
        &:hover{
            box-shadow: 0 0 15px var(--red)!important;
            transform: translate(0px, -3px) !important;
        }
      }
    }
  }
}

.bonus-link {
  text-decoration: underline;
  color: var(--red);

  @media (any-hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
}

.timer-block {
  min-height: 48px;
  transform: translate(-3px, 0px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--red);
  border-radius: 0 6px 6px 0;
  background-color: rgb(78 0 72 / 85%);
  border-left: none;

  div {
    font-size: 12px;
  }

  span {
    font-size: 12px;
    color: var(--white-design);
  }
}

.item-timer {
  flex: 0 1 50%;
}</style>