
<template>
    <div :style="{ 'overflow-y': $isMobile() ? 'unset' : 'auto', 
    'height': $isMobile() ? 'auto' : '100%'
   }">
   <RegHeader></RegHeader>
   <v-card-text class="is-reg">
            <ValidationObserver class="bubbles-content" ref="observer" v-slot="{ validate }">
                
                <div class="space-y-2">
                    <ValidationProvider
                      name="email"
                      rules="required|email"
                      v-slot="{ errors }"
                    > 
                        <v-text-field 
                    class="is-reg__input fw" 
                    required 
                    autocomplete="off"
                    :label="isEmailUniqueTwo ? $i18n.t('emailNoUniq') : 'E-mail'"
                    outlined
                    dense
                        :class="{ 'is-reg__input_error': isEmailUniqueTwo, 'is-reg__input_success': isEmailUniqueOne }"
                        v-model="regData.email" hide-details color="purple darken-1">
                        <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                            mail
                        </v-icon>
                    </v-text-field>
                        <span v-if="errors.length" class="input-error mt-1">
                            <!-- {{ $t('emailNotValid') }} -->
                            {{ $t(errors[0]) }}
                        </span>
                        <!-- <div class="hints">
                            <transition-group name="fade" tag="div">
                                <div v-if="showHint(1)" key="1" class="mt-2 text-left hint" color="var(--red)">
                                    {{ getHint(1) }}
                                </div>
                                <div v-if="showHint(2)" key="2" class="mt-2 text-left hint" color="var(--red)">
                                    {{ getHint(2) }}
                                </div>
                                <div v-if="showHint(3)" key="3" class="mt-2 text-left hint" color="var(--red)">
                                    {{ getHint(3) }}
                                </div>
                                <div v-if="showHint(4) && !showHint(2)" key="4" class="mt-2 text-left hint" color="var(--red)">
                                    {{ getHint(4) }}
                                </div>
                                <div v-if="showHint(5)" key="5" class="mt-2 text-left hint" color="var(--red)">
                                    {{ getHint(5) }}
                                </div>
                            </transition-group>
                        </div> -->
                  </ValidationProvider>
                    <ValidationProvider
                      name="username"
                      rules="required|min3"
                      v-slot="{ errors }"
                    > 
                    <v-text-field class="is-reg__input fw" 
                    hide-details
                    autocomplete="off"
                    outlined
                    dense
                    v-maska
                    data-maska="HHHHHHHHHHHH"
                    data-maska-tokens="H:[0-9a-zA-Z]"
                    :label="isUsernameUniqueTwo ? $i18n.t('userNameNoUniq') : $i18n.t('userName')" 
                    :class="{'is-reg__input_error' : isUsernameUniqueTwo, 'is-reg__input_success' : isUsernameUniqueOne}"
                    v-model="regData.username"
                     color="purple darken-1"
                    :maxlength="12"
                    >
    
                      <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                        person
                      </v-icon>
                    </v-text-field>
                        <span v-if="errors.length" class="input-error mt-1">
                            <!-- {{ $t('emailNotValid') }} -->
                            {{ $t(errors[0]) }}
                        </span>
                  </ValidationProvider>
                    <ValidationProvider
                      name="password"
                      rules="required|min8"
                      v-slot="{ errors }"
                    > 
                    <v-text-field :type="showPassword ? 'text' : 'password'"
                    outlined
                    autocomplete="off"
                    dense
                    :append-icon="showPassword ? 'visibility_off' : 'visibility'" @click:append="showPassword = !showPassword"
                    class="isPassword__input fw" hide-details 
                    :label="$t('password')" v-model="regData.password"
                     color="purple darken-1">
          
                    <v-icon slot="prepend-inner" color="var(--main-flamingo)">
                      lock
                    </v-icon>
                  </v-text-field>
                        <span v-if="errors.length" class="input-error mt-1">
                            <!-- {{ $t('regPassAlert') }} -->
                            {{ $t(errors[0]) }}
                        </span>
                  </ValidationProvider>
                    <ValidationProvider
                      name="currency"
                      rules="required"
                      v-slot="{ errors }"
                    > 
                    <v-select
              class="is-reg__select"
              dense
              hide-details
              :items="currencies"
              :menu-props="{ bottom: true, offsetY: true, contentClass: 'selectMain' }"
              v-model="regData.currency"
              :label="$t('currency')"
              color="var(--main-flamingo)"
              outlined
              >
              <!-- item-text="text"
              item-value="value" -->
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center">
                <!-- <img
                  :src="item.image"
                  width="24"
                  height="24"
                  class="mr-2"
                /> -->
                {{ item.text }}
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div class="d-flex align-center">
                  <!-- <img
                    :src="item.image"
                    width="24"
                    height="24"
                    class="mr-2"
                  /> -->
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </div>
            </template>
              </v-select>
                        <span v-if="errors.length" class="input-error mt-1">
                            {{ $t('requiredField') }}
                            <!-- {{ $t(errors[0]) }} -->
                        </span>
                  </ValidationProvider>
                    <ValidationProvider
                      name="incognit"
                      rules="required"
                      v-slot="{ errors }"
                    > 
                    <v-checkbox v-model="regData.show_info" 
                    id="incognit"
                    color="var(--main-flamingo)"
                    hide-details
                    class="mt-0 pt-0">
                        <template v-slot:label>
                          <label for="incognit" class="checkbox-label">
                            <span>{{ $t('playerIncognit') }}</span>
                          </label>
                        </template>
                      </v-checkbox>
                        <span v-if="errors.length" class="input-error mt-1">
                            {{ $t('requiredField') }}
                            <!-- {{ $t(errors[0]) }} -->
                        </span>
                  </ValidationProvider>
                    <ValidationProvider
                    ref="agreeProvider"
                      name="agree"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="{ errors }"
                    > 
                    <v-checkbox v-model="agree" 
                    id="agree"
                    color="var(--main-flamingo)"
                    hide-details
                    class="mt-0 pt-0">
                        <template v-slot:label>
                          <label for="agree" class="checkbox-label">
                            <span>{{ $t('acceptRules') }}</span>
                            <button class="font-weight-bold text-decoration-underline"
                            @click.stop="rulesModalOpen"
                            > 
                                {{ $t('privacyPolicyRules') }} 
                            </button>
                          </label>
                        </template>
                      </v-checkbox>
                        <span v-if="errors.length" class="input-error mt-1">
                            {{ $t('requiredField') }}
                            <!-- {{ $t(errors[0]) }} -->
                        </span>
                  </ValidationProvider>
                    <ValidationProvider
                      name="agreeBonuses"
                        rules=""
                      v-slot="{ errors }"
                    > 
                    <v-checkbox 
                    disabled
                    v-model="agreeBonuses" 
                    id="agreeBonuses"
                    color="var(--main-flamingo)"
                    hide-details
                    class="mt-0 pt-0 custom-checkbox">
                        <template v-slot:label>
                          <label for="agreeBonuses" class="checkbox-label font-weight-bold">
                            <span>{{ $t('wannaGetBonuses') }} 🎁💰</span>
                          </label>
                        </template>
                      </v-checkbox>
                        <span v-if="errors.length" class="input-error mt-1">
                            {{ $t('requiredField') }}
                            <!-- {{ $t(errors[0]) }} -->
                        </span>
                  </ValidationProvider>
                  
                </div>
                <!-- :class="{'_success' : isEmailUniqueOne}" -->
                <v-btn :loading="loading" class="mt-2 all-buttons-fw all-buttons-fw_green"
                @click="handleSubmit(validate)">
                <span>{{ $i18n.t('registration') }}</span>
            </v-btn>
            <reg-banner-bonuses
            class="mt-2"
            v-if="$isMobile()"
            />
        </ValidationObserver>
        <ul class="bg-bubbles-reg pa-0" v-if="$isMobile()">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        </v-card-text>
    </div>
</template>

<script>
//import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
//import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import RegBannerBonuses from "./RegBannerBonuses.vue";
import { vMaska } from "maska"
import fingerprint from "@/plugins/fingerprint";
import disableAutocomplete from '@/disableAutocomplete'
import RegHeader from './RegHeader'
import { mapGetters } from "vuex";
export default {
    directives: { maska: vMaska },
    components: {
        RegHeader,
        RegBannerBonuses,
    },
    name: 'RegNoInstant.vue',
    data() {
        return {
            loading: false,
            regData: {
                email: '',
                password: '',
                currency: 'USD',
                show_info: true,
            },
            agreeBonuses: true,
            agree: false,
            isEmailUniqueTwo: false,
            isEmailUniqueOne: false,
            isUsernameUniqueOne: false,
            isUsernameUniqueTwo: false,
            showPassword: false,
        }
    },
    beforeRouteEnter(to, from, next) {
  next(vm => {
    disableAutocomplete.disable();
  });
},
    mounted() {
        disableAutocomplete.disable();
    },
    watch: {
      '$route'(to, from) {
            this.$refs.observer.reset();
        }
},
    methods: {
        rulesModalOpen() {
      this.$store.commit("regComponents/setRulesModal", true);
    },
        handleSubmit(validate) {
            const agree = this.$refs.agreeProvider.validate();
            validate().then((success) => {
                if (success && agree) {
                    this.toRegistration();
                }
            })
        },
        async handleEmail() {
            try {
                await this.$store.dispatch('checkEmailUniqueness', this.regData.email);
                if (this.$store.state.emailIsUnique) {
                    this.isEmailUniqueOne = true;
                    this.isEmailUniqueTwo = false;
                    this.regData.email = this.regData.email.replace(' ', '');
                    return true;
                } else {
                    this.isEmailUniqueTwo = true;
                    this.isEmailUniqueOne = false;
                    this.loading = false;
                    return false;
                }
            } catch (error) {
                console.error('Email Error Unique:', error);
            }
        },
        async handleUsername() {
            try {
                await this.$store.dispatch('checkUsernameUniqueness', this.regData.username);
                if(this.$store.state.usernameIsUnique){
                  this.isUsernameUniqueOne = true;
                  this.isUsernameUniqueTwo = false;
                  this.regData.username = this.regData.username.replace(' ', '');
                  return true;
                } else{
                  this.isUsernameUniqueOne = false;
                  this.isUsernameUniqueTwo = true;
                  this.loading = false;
                  return false;
                } 
                } catch (error){
                  console.error('Username Error Unique:', error);
                }
        },
        async toRegistration() {
          this.loading = true;
            const email = await this.handleEmail();
            const username = await this.handleUsername();
            // setTimeout(() => { }, 1000);
                if (email && username) {
                    const finger = await fingerprint.get();
                    const data = this.regData;
          const submitData = {
            email: data.email,
            username: data.username,
            pass: data.password,
            currency: data.currency || "USD",
            fingerprint: finger,
            show_info: data.show_info,
          };
          // console.log(submitData);
          const [res, error] = await this.$store.dispatch(
            "awaitEmailReg",
            submitData
          );
          if (res) {
            const notification = {
              type: "info",
              message: this.$t('confirmEmail'),
              clickText: this.$t(
                'confirmEmailResend'
              ),
              click: 'resendLetter'
            };
            
            this.$store.dispatch("alerts/addHeader", notification);
            await this.$store.commit('updateRegData', {
              id: data.username,
              pass: data.password,
            });
            await this.$router.push("/deposit");
            this.$store.dispatch('initializeWebSocket');
            this.isEmailUniqueTwo = false;
            this.isEmailUniqueOne = false;
            this.isUsernameUniqueOne = false;
            this.isUsernameUniqueTwo = false;
            //this.currencies.forEach((currency) => {
            //  currency.selected = false;
            //});
          } else{
            const notification = {
              type: "error",
              message: this.$t("justError"),
            };
            this.$store.dispatch("alerts/add", notification);
          }
          this.loading = false;
                }
           
        },
    },
    computed:{
        ...mapGetters({
            currencies: 'getCurrent',
        }),
        getHint() {
            return (index) => {
                if (index === 1) {
                    return `${this.$i18n.t('forExample')}`;

                } else if (index === 2) {
                    return `${this.$i18n.t('forSecondHint')}`;
                } else if (index === 3) {
                    return `${this.$i18n.t('forThirdHint')}`;
                } else if (index === 4) {
                    return `${this.$i18n.t('forFourHint')}`;
                } else if (index === 5) {
                    return `${this.$i18n.t('forFiveHint')}`;
                } else {
                    return '';
                }
            };
        },
        showHint() {
            return (index) => {
                if (index === 1 && this.email.length < 1) {
                    return true;
                } else if (index === 2 && this.email.length > 0 && this.email.length === 1) {
                    return true;
                } else if (index === 3 && this.email.length > 1 && !this.email.includes('@')) {
                    return true;
                } else if (index === 4 && this.email.includes('@') && !this.email.includes('.')) {
                    return true;
                } else if (index === 5 && this.email.includes('@') && this.email.includes('.')) {
                    return true;
                } else {
                    return '';
                }
            };
        },
    },
}
</script>

<style lang="scss" scoped>
.bubbles-content{
    position: relative;
    z-index: 1;
}
.bg-bubbles-reg {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;

  li {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    // background-color: rgba(255, 255, 255, 0.25);
    background: url("../../../assets/images/bubles/reg/white-heart-bubble.svg") 0 /
      100% no-repeat;
    bottom: -160px;
    opacity: 0.3;
    -webkit-animation: square 25s infinite;
    animation: square 25s infinite;

    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    &:nth-child(1) {
      left: 10%;
      background: url("../../../assets/images/bubles/reg/cards-spades-bubble.svg") 0 /
        100% no-repeat;
    }

    &:nth-child(2) {
      left: 20%;
      background: url("../../../assets/images/bubles/reg/cards-diamonds-bubble.svg") 0 /
        100% no-repeat;
      width: 80px;
      height: 80px;

      animation-delay: 2s;
      animation-duration: 17s;
    }

    &:nth-child(3) {
      left: 25%;
      animation-delay: 4s;
      background: url("../../../assets/images/bubles/reg/cards-clubs-bubble.svg") 0 /
        100% no-repeat;
    }

    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      background: url("../../../assets/images/bubles/reg/cards-spades-bubble.svg") 0 /
        100% no-repeat;
      animation-duration: 22s;

      // background-color: rgba(255, 255, 255, 0.25);
    }

    &:nth-child(5) {
      left: 70%;
      background: url("../../../assets/images/bubles/reg/cards-clubs-bubble.svg") 0 /
        100% no-repeat;
    }

    &:nth-child(6) {
      left: 80%;
      width: 120px;
      height: 120px;

      animation-delay: 3s;
      // background-color: rgba(255, 255, 255, 0.25);
    }

    &:nth-child(7) {
      left: 32%;
      width: 160px;
      height: 160px;

      animation-delay: 7s;
    }

    &:nth-child(8) {
      left: 55%;
      width: 20px;
      height: 20px;

      animation-delay: 15s;
      animation-duration: 40s;
    }

    &:nth-child(9) {
      left: 25%;
      width: 10px;
      height: 10px;

      animation-delay: 2s;
      animation-duration: 40s;
      // background-color: rgba(255, 255, 255, 0.25);
    }

    &:nth-child(10) {
      left: 90%;
      width: 160px;
      height: 160px;

      animation-delay: 11s;
    }
  }
}

.is-reg {
    //background-color: transparent;
    padding: 20px!important;
    background-color: var(--white);
    position: relative;
   span{
    display: inline-block;
    width: 100%;
   }
    &__form {
        
    }
    &__button{
        transition: all 0.3s;
        display: flex;
        justify-content:center;align-items: center;
        p{
            transition: all 0.3s;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            margin: 0;
        }
        span{
            transition: all 0.3s;
            position: static;
        }
        &._success{
            background-color: var(--green-success)!important;
            p{
                opacity: 1;
                overflow: visible;
                position: static;
            }
           span{
            opacity: 0;
            overflow: hidden;
            position: absolute;
           }
        }
    }
}

.hints {
    position: relative;
    transition: opacity 0.3s;
}

.hint {
    position: absolute;
    top: 0;
    left: 0;
    color: #9ea4ae;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}
</style>