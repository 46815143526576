<template>
  
  <v-card class="front-bonus">
    <div class="front-bonus">
    <v-card-title class="front-bonus__header header-bonus">
      <div class="header-bonus__label">

        <font-awesome-icon style="font-size: 12px;" icon="gift" />
      </div>
      <!-- {{ $i18n.t('bonusesFree') }} -->
      <div class="header-bonus__title"> {{ $t('welcomeBonus') }}</div>
      <!-- @click="toggleCardFlip(index)"  -->
      <div @click="flipCard" class="header-bonus__label">
        <font-awesome-icon icon="info" style="font-size: 12px;" />
      </div>
    </v-card-title>
    <v-card-text style="width: auto;" class="py-0 front-bonus__main main-bonus">
      <!--<div class="main-bonus__image">
              <img src="@/assets/images/bonus.png" width="100%" /> 
            </div>-->
      <div class="main-bonus__title">
        <span>{{ bonus_percent }}</span>
        <span> {{ $t('bonus') }} </span>
        <!-- <p>+ 100 FREESPINS</p> -->
      </div>
    </v-card-text>
    <v-card-actions v-if="$route.path === '/profile/promotions' " class="main-bonus__activate justify-center" :style="{'background-color' : isActive ? 'rgb(78 0 72 / 40%)' : 'none'}">
      <v-btn v-if="!isActive" class="all-buttons-t main-bonus__button main-bonus__button_fix" :loading="loading" @click="activateBonus">{{ $t('bonusesWillActive') }}</v-btn>
      <div v-if="isActive" class="d-flex align-center font-weight-bold">
        <router-link to="/profile/promo-my/bonus" class="bonus-link"><span>{{ $t('bonus') }} </span></router-link>
        <span class="ml-1">{{ $t('bonusesWasActivated') }}</span>
      </div>
      <!-- <div v-if="is_used" class="d-flex align-center font-weight-bold ">
        <router-link to="/profile/promo-my/bonus" class="bonus-link" ><span>{{ $t('bonus') }} </span></router-link>
        <span class="ml-1">{{ $t('bonusesWasUsed') }}!</span>
      </div> -->
      
      <!-- <div class="all-buttons-t main-bonus__button" @click="handleClick"> Timer </div> -->

    </v-card-actions>
    <v-card-actions v-if="$route.path === '/profile/promo-my/bonus'" class="main-bonus__activate justify-center">
      <div v-if="isActive" class="main-bonus__button item-timer" @click="toDeposit">{{ $t('toBonusDeposit') }}</div>
      <div v-if="isActive" class="font-weight-bold timer-block item-timer">
        <span>{{ $t('timeLeftBonus') }}</span>
        <Countdown :expireInSeconds="expire_in_seconds" />
    </div>
      <!-- <div class="all-buttons-t main-bonus__button" @click="handleClick"> Timer </div> -->
      <!-- <div v-if="is_used" class="d-flex align-center font-weight-bold">
        <span class="ml-1">{{ $t('bonusWasUsed') }}!</span>
      </div> -->
    </v-card-actions>
  </div>
  </v-card>

</template>

<script>


import Countdown from '@/components/Countdown/Timer'
import { mapGetters } from 'vuex';
export default {
  name: 'FrontDepositBonus',
  props: {
    index: {
      type: Number,
      required: true,
    },
    bonusId: {
      type: Number,
      required: true,
    },
    status: String,
    type: String,
    expire_in_days: String,
    expire_in_seconds: Number,
    bonus_percent: String,
  },
  components:{
    Countdown,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      depositBonuses: 'bonuses/getDepositBonuses',
      user: 'getUserInfo'
    }),
    isActive (){
      return this.status === 'active';
    },
    bonusItems() {
      if (this.depositBonuses) {
        return this.depositBonuses.map(bonus => ({
          status: bonus.status,
          type: bonus.type,
          id: bonus.id,
          bonus_percent: bonus.bonus_percent + '%',
          wager: bonus.wager,
          expire_in_days: bonus.expire_in_days + ' ' + this.$t('day'),
          expire_in_seconds: bonus.expire_in_seconds,
          min_dep: bonus.min_dep + ' ' + this.user.currency,
          max_dep: bonus.max_dep + ' ' + this.user.currency,

        }));
      } else {
        return [];
      }
    },
    
  },
  methods: {
    toDeposit(){
      this.$router.push('/deposit');
    },
    activateBonus() {
      this.loading = true;
      this.$store.dispatch('bonuses/activateUserBonus', { id: this.bonusId })
       .then(([res, error_text]) => {
         if (res) {
          const notification = {
              type: "success",
              message: this.$t('bonusHasBeenActivated'),
            };
          this.$store.dispatch("alerts/add", notification);
          this.toDeposit();
          this.loading = false;
         } else {
          const notification = {
              type: "error",
              message: this.$t('justError'),
            };
          this.$store.dispatch("alerts/add", notification);
          this.loading = false;
         }
       })
       .catch((error) => {
         this.loading = false;
       });
    },
    flipCard() {
      this.$store.commit('bonuses/flipBonus', { bonuses: 'depositBonuses', index: this.index });
    },
  },
}
</script>

<style lang="scss" scoped>
.front-bonus {
  //background: linear-gradient(173.41deg,#0094ff 4.14%,#5607d6 87.72%)!important;
  //background: var(--dark-flamingo)!important;
  background: url('../../../../../assets/images/bonusCard.jpg') 0 70% / cover no-repeat;
  color: var(--white) !important;
  //height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px !important;

  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
  }

  &__content {}

  &__main {
    flex: 1 1 100%;
    position: relative;
    z-index: 2;
  }

  &__timer {
    position: relative;
    z-index: 2;
  }

}

.header-bonus {
  padding: 10px;

  &__label {
    height: 24px;
    justify-content: center;
    
    position: relative;
    width: 24px;
    align-items: center;
    display: flex;
    position: relative;
    transition: background-color 0.3s;
    &:last-child{
      cursor: pointer;
      
      @media (any-hover: hover){
        &:hover{
          &::before {
            background-color: hsla(0, 0%, 100%, .4);
          }
        }
      }
      
    }
    &::before {
      background-color: hsla(0, 0%, 100%, .2);
      border-radius: 50%;
      content: "";
      //height: calc(100% - 20px);
      height: 24px;
      width: 24px;
      position: absolute;
      //width: calc(100% - 20px);
    }
  }

  &__title {
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;

  }
}

.main-bonus {
  cursor: default;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  @media (any-hover: hover){
    &:hover{
      .main-bonus__title{
        transform: scale(1.08);
        
      }
    }
  }
  &__image {}

  &__title {
    transition: transform 0.3s;
    text-transform: uppercase;
    font-weight: 900;
    color: var(--white) !important;
    line-height: 1;

    span {
      font-size: 75px;
    }

    p {
      font-size: 30px;
    }
  }

  &__activate {
    position: relative;
    z-index: 2;
    border-radius: 0 0 16px 16px;
  }

  &__button {
    min-height: 50px;
    padding: 0 4px;
    
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px !important;
    font-weight: 700;
    flex: 1 1 100%;
    position: relative;
    z-index: 5;
    transform: translate(3px,0px)!important;
    background-color: var(--red);
    border-radius: 6px 0 0 6px;
    transition: all 0.3s;
    @media (any-hover: hover){
      &:hover{
          box-shadow: 0 0 15px var(--red)!important;
          transform: translate(3px,0px)!important;
      }
    }
    &_fix{
      transform: translate(0px,0px)!important;
      @media (any-hover: hover){
        &:hover{
            box-shadow: 0 0 15px var(--red)!important;
            transform: translate(0px, -3px) !important;
        }
      }
    }
  }
}
.bonus-link{
  text-decoration: underline;
  color: var(--red);
  @media (any-hover: hover){
    &:hover{
      text-decoration: none;
    }
  }
}
.timer-block{
  min-height: 48px;
  transform: translate(-3px,0px);
  display: flex;
  flex-direction: column;
  justify-content:center;align-items: center;
  border: 1px solid var(--red);
  border-radius: 0 6px 6px 0;
  background-color: rgb(78 0 72 / 85%);
  border-left: none;
  div{
    font-size: 12px;
  }
  span{
    font-size: 12px;
    color: var(--white-design);
  }
}
.item-timer{
  flex: 0 1 50%;
}

</style>