<template>
    <flip-deposit-bonus :index="index">
        <template slot="front">
            <front-deposit-bonus 
            :index="index" 
            :bonusId="cardDeposit.id" 
            :cardDeposit="cardDeposit"
            :status="cardDeposit.status"
            :is_used="cardDeposit.is_used"
            :type="cardDeposit.type"
            :expire_in_days="cardDeposit.expire_in_days + ' ' + this.$t('day')"
            :expire_in_seconds="cardDeposit.expire_in_seconds"
            :bonus_percent="cardDeposit.bonus_percent + '%'"
            ></front-deposit-bonus>
        </template>
        <template slot="back">
            <back-deposit-bonus :index="index"
            :type="cardDeposit.type"
             :status="cardDeposit.status"
            :bonusId="cardDeposit.bonusId"
            :bonus_percent="cardDeposit.bonus_percent + '%'"
            :wager="cardDeposit.wager"
            :expire_in_days="cardDeposit.expire_in_days + ' ' + this.$t('day')" 
            :min_dep="cardDeposit.min_dep + ' ' + this.user.currency"
            :max_dep="cardDeposit.max_dep + ' ' + this.user.currency"
            :currency_limits="cardDeposit.currency_limits"
            ></back-deposit-bonus>
        </template>
      </flip-deposit-bonus>

</template>

<script>
import { mapGetters } from 'vuex';
import BackDepositBonus from './BackDepositBonus.vue';
import FrontDepositBonus from './FrontDepositBonus.vue';
import FlipDepositBonus from './FlipDepositBonus.vue'
    export default {
        name: 'DepositBonus',
        components: {
            FrontDepositBonus,
            BackDepositBonus,
            FlipDepositBonus,
        },
        computed:{
            ...mapGetters({
                user: 'getUserInfo'
            })
        },
        props: {
    index: {
      type: Number,
      required: true,
    },
    bonusId: {
      type: Number,
      required: true,
    },
    cardDeposit: {
      type: Object, 
      required: true,
    },
  },
        data() {
            return {
                
            }
        },
        
    }
</script>

<style lang="scss" scoped>

</style>