var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"px-0",staticStyle:{"max-width":"900px"}},[_c('v-row',{},[_c('v-col',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"pa-0 text-uppercase title-card",staticStyle:{"color":"var(--white)"}},[_vm._v(" "+_vm._s(_vm.$t('bonusesTitle'))+" ")]),_c('v-btn',{staticClass:"all-buttons-t",on:{"click":_vm.openPromocodeDialog}},[_vm._v(_vm._s(_vm.$t('promoTitle')))])],1),_c('PromoCode')],1),(_vm.depositBonusActive || _vm.spinsBonusActive || _vm.nextDepositBonusActive)?[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"py-0"},[_c('v-card-title',{staticClass:"pa-0 mb-n6 text-uppercase title-card",staticStyle:{"font-size":"14px","color":"var(--white)"}},[_vm._v(_vm._s(_vm.$i18n.t('depositBonuses'))+" ")])],1)],1),_c('v-row',{staticStyle:{"overflow":"hidden"}},[_c('v-col',{staticClass:"py-10 pr-0"},[(_vm.depositBonusActive)?[_c('swiper',{staticStyle:{"height":"450px"},attrs:{"slides-per-view":1,"breakpoints":{
            '320': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '425': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '600': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '768': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '1024': {
              slidesPerView: 'auto',
              spaceBetween: 10
            }
          }}},_vm._l((_vm.arrayDepositBonuses),function(deposit,index){return _c('swiper-slide',{key:'welcomeCard_' + index,staticClass:"width-slide"},[_c('deposit-bonus',{attrs:{"index":index,"bonusId":deposit.id,"cardDeposit":deposit}})],1)}),1)]:_vm._e(),(!_vm.depositBonusActive)?_c('v-col',{staticClass:"d-flex flex-column mx-auto",staticStyle:{"max-width":"600px"}},[_c('span',{staticClass:"mb-3",staticStyle:{"color":"var(--white)","font-size":"22px","font-weight":"800"}},[_vm._v(" "+_vm._s(_vm.$t('noActivBonuses'))+" ")]),_c('v-btn',{staticClass:"all-buttons-fw",attrs:{"to":"/profile/promotions"}},[_vm._v(_vm._s(_vm.$t('selectBonus')))])],1):_vm._e()],2)],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"py-0"},[_c('v-card-title',{staticClass:"pa-0 mb-n6 text-uppercase title-card",staticStyle:{"font-size":"14px","color":"var(--white)"}},[_vm._v(_vm._s(_vm.$i18n.t('spinsBonuses'))+" ")])],1)],1),_c('v-row',{staticStyle:{"overflow":"hidden"}},[_c('v-col',{staticClass:"py-10 pr-0"},[(_vm.spinsBonusActive)?[_c('swiper',{staticStyle:{"height":"450px"},attrs:{"slides-per-view":1,"breakpoints":{
            '320': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '425': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '600': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '768': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '1024': {
              slidesPerView: 'auto',
              spaceBetween: 10
            }
          }}},_vm._l((_vm.arraySpinsBonuses),function(spins,index){return _c('swiper-slide',{key:'spinsCard_' + index,staticClass:"width-slide"},[_c('spins-bonus',{attrs:{"index":index,"bonusId":spins.id,"cardSpins":spins}})],1)}),1)]:_c('v-col',{staticClass:"d-flex flex-column mx-auto",staticStyle:{"max-width":"600px"}},[_c('span',{staticClass:"mb-3",staticStyle:{"color":"var(--white)","font-size":"22px","font-weight":"800"}},[_vm._v(" "+_vm._s(_vm.$t('noActivBonuses'))+" ")]),_c('v-btn',{staticClass:"all-buttons-fw",attrs:{"to":"/profile/promotions"}},[_vm._v(_vm._s(_vm.$t('selectBonus')))])],1)],2)],1),_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"pa-0 mb-n6 text-uppercase title-card",staticStyle:{"font-size":"14px","color":"var(--white)"}},[_vm._v(_vm._s(_vm.$i18n.t('nextDepositBonuses'))+" ")])],1)],1),_c('v-row',{staticStyle:{"overflow":"hidden"}},[_c('v-col',{staticClass:"py-10 pr-0"},[(_vm.nextDepositBonusActive)?[_c('swiper',{staticStyle:{"height":"450px"},attrs:{"slides-per-view":1,"breakpoints":{
            '320': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '425': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '600': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '768': {
              slidesPerView: 'auto',
              spaceBetween: 10
            },
            '1024': {
              slidesPerView: 'auto',
              spaceBetween: 10
            }
          }}},_vm._l((_vm.arrayNextDepositBonuses),function(futureCard,index){return _c('swiper-slide',{key:'futureCard_' + index,staticClass:"width-slide"},[_c('bonus-future-one',{attrs:{"index":index,"bonusId":futureCard.id,"cardFuture":futureCard}})],1)}),1)]:_c('v-col',{staticClass:"d-flex flex-column mx-auto",staticStyle:{"max-width":"600px"}},[_c('span',{staticClass:"mb-3",staticStyle:{"color":"var(--white)","font-size":"22px","font-weight":"800"}},[_vm._v(" "+_vm._s(_vm.$t('noActivBonuses'))+" ")]),_c('v-btn',{staticClass:"all-buttons-fw",attrs:{"to":"/profile/promotions"}},[_vm._v(_vm._s(_vm.$t('selectBonus')))])],1)],2)],1)]:[_c('v-col',{staticClass:"d-flex flex-column mx-auto",staticStyle:{"max-width":"600px"}},[_c('span',{staticClass:"mb-3",staticStyle:{"color":"var(--white)","font-size":"22px","font-weight":"800"}},[_vm._v(" "+_vm._s(_vm.$t('noActivBonuses'))+" ")]),_c('v-btn',{staticClass:"all-buttons-fw",attrs:{"to":"/profile/promotions"}},[_vm._v(_vm._s(_vm.$t('selectBonus')))])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }