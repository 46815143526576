// bonuses.js
import axios from "axios";
const state = {
  bonusTournamentFlipped: false,
  bonusNoLoginFlipped: false,
  spinsBonuses: [],
  depositBonuses: [],
};

const mutations = {
  set(state, { type, items }) {
    state[type] = items;
  },
  flipBonus(state, payload) {
    const { bonuses } = payload;
    state[bonuses][payload.index].flipped =
      !state[bonuses][payload.index].flipped;
  },
  noLoginFlip(state, value) {
    state.bonusNoLoginFlipped = value;
  },
};

const getters = {
  getSpinsBonuses: (state) => state.spinsBonuses,
  getDepositBonuses: (state) => state.depositBonuses,
};

const actions = {
  async getUserBonuses({ commit, state }) {
    // /api/getUserBonuses
    const jwt = localStorage.getItem("jwt"); // jwt
    try {
      const response = await axios.get(`/api/bonuses/my`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (response.status === 200) {
        // deposit & freeSpins
        const userBonuses = response.data;
        const { deposit, free_rounds } = userBonuses;
        const flippedDep = deposit.map((dep) => ({
          ...dep,
          flipped: false,
        }));
        const flippedSpin = free_rounds.map((spin) => ({
          ...spin,
          flipped: false,
        }));
        state.depositBonuses = flippedDep;
        state.spinsBonuses = flippedSpin;
      }
    } catch (error) {
      console.log("error = ", error);
    }
  },
  async activateUserBonus({ dispatch }, data) {
    let res = false;
    let error_type = false;
    let error_text = "";
    let json = {
      bonus_id: data.id,
    };

    const jwt = localStorage.getItem("jwt");

    await axios
      .patch(`/api/bonuses/${data.id}/activate`, json, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (!("error_type" in response.data)) {
            res = true;
          } else {
            error_type = response.data.error_type;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (res) {
      await dispatch("getUserBonuses");
    } else {
      switch (error_type) {
        case 1:
          error_text = this.i18nPromise.t("bonusExist");
          break;
        case 2:
          error_text = this.i18nPromise.t("bonusUsed");
          break;
        case 3:
          error_text = this.i18nPromise.t("depositAbsent");
          break;
        case 4:
          error_text = this.i18nPromise.t("minDepConditions");
          break;
        default:
          error_text = this.i18nPromise.t("error");
          break;
      }
    }
    return [res, error_text];
  },
  async deleteUserBonus({ dispatch }, id) {
    const jwt = localStorage.getItem("jwt");
    await axios
      .delete(`/api/bonuses/my/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
